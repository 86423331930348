import { JoinLocationState } from './form';
import { SocialJoinControllerService } from '@/__generated__/CommonApi';
import iconFacebook from '@/assets/img/join/ico_facebook@2x.png';
import iconGoogle from '@/assets/img/join/ico_google@2x.png';
import iconNaver from '@/assets/img/join/ico_naver@2x.png';
import Button from '@/components/Button';
import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import PopupModal from '@/components/PopupModal';
import NaverLogin, {
  NaverSuccessResult,
} from '@/components/SocialLogin/NaverLogin';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { FormContainer, SocialButton } from '@/page-blocks/auth/AuthCommon';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { navigate, PageProps } from 'gatsby';
import React, { FC, useCallback, useState } from 'react';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import styled from 'styled-components';

declare global {
  interface Window {
    naver: any;
  }
}

const ContentBox = styled.div`
  margin: 0 auto;
  max-width: 544px;
  h2 {
    line-height: 1.5;
    letter-spacing: -2px;
    margin-bottom: 24px;
    ${breakpoint(`mobile`)} {
      line-height: 1.58;
      letter-spacing: -1.2px;
    }
  }
  p {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: -0.8px;
    margin-bottom: 40px;
    ${breakpoint(`mobile`)} {
      font-size: 14px;
      line-height: 2;
      letter-spacing: -0.8px;
    }
  }
`;
const ContentBox1 = styled(ContentBox)`
  padding-top: 96px;
  padding-bottom: 64px;
  border-bottom: 1px solid #e5e6e8;
  ${breakpoint(`mobile`)} {
    padding-top: 48px;
  }
`;
const ContentBox2 = styled(ContentBox)`
  padding-top: 64px;
  ${breakpoint(`mobile`)} {
    padding-top: 48px;
  }
`;

const SocialButtonText = styled.span`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.8px;
`;

const Join: FC<PageProps> = ({ location }) => {
  const [popupMessage, setPopupMessage] = useState<string>();
  const [existUserType, setExistUserType] = useState<string>();
  const [tmpNavigateParam, setTmpNavigateParam] = useState<JoinLocationState>();

  // 소셜가입시 이메일 중복체크
  const socialJoinCheck = useCallback(
    async (email: string, socialId: string, socialType: 'N' | 'G' | 'F') => {
      const {
        data: _data,
      } = await SocialJoinControllerService.socialJoinBeforeEmailDuplicateCheckUsingPost(
        {
          email,
          id: socialId,
          snsDiv: socialType,
        },
      );

      const data = _data as any;

      // 아이디 또는 이메일 중복이면
      if ([`S`, `G`].includes(data.existUserType)) {
        // 오류메세지 띄우기
        setExistUserType(data.existUserType);
        setPopupMessage(data.returnMessage);
        return false;
      }

      return true;
    },
    [],
  );

  // 네이버로 회원가입
  const joinWithNaver = useCallback(
    async (result: NaverSuccessResult) => {
      const isUnique = await socialJoinCheck(
        result.user.email,
        result.user.id,
        `N`,
      );
      if (isUnique) {
        navigate(`/auth/join/form`, {
          state: {
            naver: result,
          },
        });
      } else {
        // 데이터 임시저장
        setTmpNavigateParam({
          naver: result,
        });
      }
    },
    [socialJoinCheck],
  );

  // 페이스북으로 회원가입
  const joinWithFacebook = useCallback(
    async (result: ReactFacebookLoginInfo) => {
      const isUnique =
        result.email && (await socialJoinCheck(result.email, result.id, `F`));
      if (isUnique) {
        if (result.accessToken) {
          navigate(`/auth/join/form`, {
            state: {
              facebook: result,
            },
          });
        } else {
          setPopupMessage(`페이스북 로그인에 실패하였습니다`);
        }
      } else {
        // 데이터 임시저장
        setTmpNavigateParam({
          facebook: result,
        });

        // facebook 정보에 email이 없는 경우 있음.
        if (result.email == null) {
          if (result.accessToken) {
            navigate(`/auth/join/form`, {
              state: {
                facebook: result,
              },
            });
          } else {
            setPopupMessage(`페이스북 로그인에 실패하였습니다`);
          }
        }
      }
    },
    [socialJoinCheck],
  );

  // 구글로 회원가입
  const joinWithGoogle = useGoogleLogin({
    onSuccess: async (res) => {
      const userInfo = await axios.get(
        `https://www.googleapis.com/oauth2/v3/userinfo`,
        {
          headers: { Authorization: `Bearer ${res.access_token}` },
        },
      );
      const isUnique = await socialJoinCheck(
        userInfo.data.email,
        userInfo.data.sub,
        `G`,
      );
      if (isUnique) {
        navigate(`/auth/join/form`, {
          state: {
            google: userInfo.data,
          },
        });
      } else {
        // 데이터 임시저장
        setTmpNavigateParam({
          google: userInfo.data,
        });
      }
    },
    // eslint-disable-next-line no-console
    onError: (e) => console.error(e),
  });

  return (
    <>
      <LayoutWithTitle
        location={location}
        title="회원가입"
        description="유니세프한국위원회에 오신것을 환영합니다"
      >
        <FormContainer>
          <ContentBox1>
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                일반 회원가입
              </Tit>
            </h2>
            <p>아이디, 패스워드 등록으로 회원가입 하실 수 있습니다.</p>
            <Button full onClick={() => navigate(`/auth/join/form`)}>
              회원가입하기
            </Button>
          </ContentBox1>
          <ContentBox2>
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                SNS 간편 회원가입
              </Tit>
            </h2>
            <p>SNS 계정으로 간편하게 회원가입 하실 수 있습니다.</p>
            <Row>
              <Col align="center">
                <NaverLogin
                  clientId={process.env.NAVER_LOGIN_CLIENT_ID || ``}
                  callbackUrl={`${process.env.SELF_URL}/auth/join`}
                  render={(props) => (
                    <SocialButton {...props}>
                      <img
                        src={iconNaver}
                        alt="네이버"
                        width="60"
                        height="60"
                      />
                      <br />
                      <SocialButtonText>네이버</SocialButtonText>
                    </SocialButton>
                  )}
                  onSuccess={joinWithNaver}
                  onFailure={(e) => console.error(e)}
                />
              </Col>
              <Col align="center">
                <FacebookLogin
                  appId={process.env.FACEBOOK_LOGIN_APP_ID || ``}
                  fields="name,email"
                  disableMobileRedirect
                  render={({ onClick }) => (
                    <SocialButton onClick={onClick}>
                      <img
                        src={iconFacebook}
                        alt="페이스북"
                        width="60"
                        height="60"
                      />
                      <br />
                      <SocialButtonText>페이스북</SocialButtonText>
                    </SocialButton>
                  )}
                  callback={joinWithFacebook}
                />
              </Col>
              <Col align="center">
                <SocialButton onClick={() => joinWithGoogle()}>
                  <img src={iconGoogle} alt="구글" width="60" height="60" />
                  <br />
                  <SocialButtonText>구글</SocialButtonText>
                </SocialButton>
              </Col>
            </Row>
          </ContentBox2>
        </FormContainer>
      </LayoutWithTitle>
      {popupMessage && (
        <PopupModal
          message={popupMessage}
          maxWidth="600px"
          onRequestClose={() => setPopupMessage(undefined)}
          footer={
            <>
              <Button size="sm" outline onClick={() => navigate(`/auth/login`)}>
                기존 아이디로 로그인
              </Button>
              {existUserType === `G` && (
                <>
                  <Button
                    size="sm"
                    outline
                    onClick={() => {
                      navigate(`/auth/find-id`);
                    }}
                  >
                    아이디/비밀번호 찾기
                  </Button>
                </>
              )}
            </>
          }
        />
      )}
    </>
  );
};

export default Join;
